import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionStatusRowComponent } from './subscription-status-row.component';

@NgModule({
  declarations: [SubscriptionStatusRowComponent],
  imports: [CommonModule, RouterModule, TranslateModule],
  exports: [SubscriptionStatusRowComponent],
})
export class SubscriptionStatusRowModule {}
